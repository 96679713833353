import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const PATIENT_SIGN_UP_MUTATION = gql`
  mutation PatientSignUp_UnauthenticatedSignUpSignIn_Mutation(
    $input: AuthPatientSignUpFromRxInput!
  ) {
    auth {
      patientSignUpFromRx(input: $input) {
        redirectPath
        errors {
          message
          fields {
            password
          }
        }
      }
    }
  }
`;

interface AuthPatientSignUpFromRxFieldError {
  password: string[];
}

interface AuthPatientSignUpFromRxError {
  fields: AuthPatientSignUpFromRxFieldError;
  message: string;
}

interface PatientSignUpMutationData {
  auth: {
    patientSignUpFromRx: {
      errors: AuthPatientSignUpFromRxError;
      redirectPath: string;
    };
  };
}

interface PatientSignUpAttributes {
  treatmentPlanSlug?: string;
  labRecommendationId?: string;
  uuid?: string;
  storeSlug: string;
  password: string;
  pageVersion: string;
  signUpPage: string;
  optional?: any;
}

interface Variables {
  input: {
    captchaToken: string;
    attributes: PatientSignUpAttributes;
  };
}

const usePatientSignUpMutation = (
  options?: MutationHookOptions<PatientSignUpMutationData, Variables>
) => useMutation<PatientSignUpMutationData, Variables>(PATIENT_SIGN_UP_MUTATION, options);

export type { PatientSignUpMutationData };
export { usePatientSignUpMutation, PATIENT_SIGN_UP_MUTATION };
