import { useTranslation } from "react-i18next";

import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreLogo.styles";

interface Props {
  storeLogo: string;
  storeName: string;
}

const StoreLogo = ({ storeLogo, storeName }: Props) => {
  const { t } = useTranslation();
  if (!storeLogo) return null;

  return <img src={storeLogo} css={styles.logo} alt={t(l.patientRxAuth.Logo, { storeName })} />;
};

export { StoreLogo };
