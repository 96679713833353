import { css } from "@emotion/react";
import { typography } from "aviary-tokens";

export const textCenter = css`
  text-align: center;
`;

export const emailEmphasis = css`
  font-weight: ${typography.weightSemiBold};
`;

export const takeMeToGmailBtn = css`
  margin-bottom: 1rem;
`;
