import { css } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const header = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem 2rem 2rem;
  margin-top: 3.5rem;

  @media (max-width: ${dimensions.phoneLargeMax}) {
    margin-top: 1.5rem;
  }
`;
