import { css } from "@emotion/react";

export const form = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const input = css`
  margin-bottom: 1rem;
`;
