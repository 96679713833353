import { css, type Theme } from "@emotion/react";
import { type ColorProfileTheme } from "aviary-tokens";

import { dimensions } from "@styles/index";

export const baseStyles = css`
  border-radius: ${dimensions.circleRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const size = {
  xsmall: css`
    height: 2rem;
    width: 2rem;
  `,
  small: css`
    height: 2.5rem;
    width: 2.5rem;
  `,
  medium: css`
    height: 3rem;
    width: 3rem;
  `,
  large: css`
    height: 4rem;
    width: 4rem;
  `,
};

export const countSize = {
  xsmall: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.footnote.fontSize};
  `,
  small: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.caption.fontSize};
  `,
  normal: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.body.fontSize};
  `,
  large: (theme: Theme) => css`
    font-size: ${theme.aviaryTypography.h3.fontSize};
  `,
};

export const countColors = (theme: ColorProfileTheme) => css`
  color: ${theme.textBase};
`;

export const colorStyles = {
  system: (theme: Theme) => css`
    background-color: ${theme.system.backgroundMuted};
  `,
  light: (theme: Theme) => css`
    background-color: ${theme.light.backgroundBase};
    border-color: transparent;
  `,
  success: (theme: Theme) => css`
    background-color: ${theme.accent.forest.backgroundMuted0};
  `,
};

export const iconFillColor = {
  system: (theme: Theme) => css`
    path {
      fill: ${theme.system.textBase};
    }
  `,
  light: (theme: Theme) => css`
    path {
      fill: ${theme.light.textBase};
    }
  `,
  success: (theme: Theme) => css`
    path {
      fill: ${theme.accent.forest.textBody};
    }
  `,
};
