import { css } from "@emotion/react";
import type { Theme } from "@emotion/react";

import { dimensions } from "@styles";

export const wrapper = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 2rem;

  @media (max-width: ${dimensions.tablet}) {
    justify-content: center;
  }
`;

export const wrapperPadding = css`
  padding: 1rem 2rem;
`;

export const bottomBorder = (theme: Theme) => css`
  border-bottom: 1px solid ${theme.separator.base};
  padding-bottom: 0px;
`;
