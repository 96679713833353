import { css, type Theme } from "@emotion/react";

export const flag = css`
  width: 1.125rem;
  margin-right: 0.5rem;
`;

export const activeButton = (theme: Theme) => css`
  border-color: ${theme.primary.borderBase};
  border-width: 1.25px;
  border-style: solid;
`;
