import type { HTMLProps, ReactNode } from "react";

import * as styles from "./Header.styles";

interface Props extends HTMLProps<HTMLDivElement> {
  children: ReactNode;
}

const Header = ({ children, ...rest }: Props) => (
  <div css={styles.header} {...rest}>
    {children}
  </div>
);

export { Header };
