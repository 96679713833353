import { css } from "@emotion/react";

export const pill = css`
  width: fit-content;

  &:not(:last-child) {
    margin-right: 1rem;
  }
  @media (max-width: 1027px) {
    &:not(:last-child) {
      margin-right: 0;
    }
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

export const pillContainer = css`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (max-width: 1027px) {
    flex-direction: column;
  }
`;
