import { css, type Theme } from "@emotion/react";

import * as animations from "@styles/emotion-styles/animations";
import * as timing from "@styles/emotion-styles/timing";

export const noStyleButton = (theme: Theme) => css`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  color: ${theme.text.body};
  margin: 0 auto;
  @supports (width: fit-content) {
    width: fit-content;
  }

  &:active {
    .icon-chevron {
      margin-right: 2rem;
    }
  }
`;

export const chevron = (theme: Theme) => css`
  margin-right: 1rem;
  transition: margin ${timing.fast} ${animations.easeOutCubic};
  color: ${theme.success.textBase};
`;
