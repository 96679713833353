import { ForgotPasswordContent } from "@unauthenticated/signupSignin/components/ForgotPasswordContent/ForgotPasswordContent";
import { SignInLayout } from "@unauthenticated/signupSignin/components/SignInLayout/SignInLayout";

const ForgotPasswordPage = () => {
  return (
    <SignInLayout>
      <ForgotPasswordContent />
    </SignInLayout>
  );
};

export { ForgotPasswordPage };
