import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Typography, useToast } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { useLocation } from "@shared/react-router-dom/react-router-dom";
import { StorePlatform } from "@shared/types/StorePlatform";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { ErrorBox } from "@unauthenticated/shared/components/ErrorBox/ErrorBox";
import { RecaptchaErrorBox } from "@unauthenticated/shared/components/RecaptchaErrorBox/RecaptchaErrorBox";
import { SignInWrapper } from "@unauthenticated/shared/components/SignInWrapper/SignInWrapper";
import { PasswordSentPage } from "@unauthenticated/signupSignin/components/ForgotPasswordContent/PasswordSentPage/PasswordSentPage";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { ForgotPasswordForm } from "../ForgotPasswordForm/ForgotPasswordForm";

import { useUserForgotPasswordEmail } from "./UserForgotPasswordEmail.mutation";

import * as styles from "./ForgotPasswordContent.styles";

const ForgotPasswordContent = () => {
  const { tablet } = useBreakpoints();
  const { isEmerson } = useSharedGlobalConfig();

  const search = useLocation().search;
  const { makeToast } = useToast();
  const urlParams = new URLSearchParams(search);
  const urlEmail = urlParams.get("email");
  const error = new URLSearchParams(search).get("error");
  const storePlatform = isEmerson ? StorePlatform.EMERSON : StorePlatform.FULLSCRIPT;
  const [email, setEmail] = useState(urlEmail || "");
  const [recaptchaError, setRecaptchaError] = useState(false);

  const { t } = useTranslation();

  const [requestNewPassword, { data, loading }] = useUserForgotPasswordEmail({
    onCompleted: completedData => {
      if (!completedData?.auth?.userForgotPasswordEmail?.emailSent) {
        makeToast("error", t(l.signIn.TryAgainResetPassword));
      }
    },
    onError: ({ message }) => {
      makeToast("error", message);
    },
  });

  const onSubmit = e => {
    e.preventDefault();
    gRecaptchaExecute(
      captchaToken =>
        requestNewPassword({
          variables: {
            input: {
              captchaToken,
              attributes: {
                email,
                storePlatform,
              },
            },
          },
        }),
      setRecaptchaError
    );
  };

  const isEmailSent = data?.auth?.userForgotPasswordEmail?.emailSent;

  const renderEmailSentContent = <PasswordSentPage email={email} />;
  const title = isEmerson ? t(l.signIn.Emerson.ResetPassword) : t(l.signIn.ResetPassword);
  const subtitle = isEmerson ? t(l.signIn.Emerson.ResetLinkText) : t(l.signIn.HappensToTheBest);

  const renderForgotPasswordForm = (
    <div css={styles.container}>
      <SignInWrapper>
        <Typography type={tablet.greaterThan ? "h2" : "h3"}>{title}</Typography>
        {recaptchaError && <RecaptchaErrorBox />}
        {!!error && (
          <ErrorBox
            error={t(l.signIn.LinkIsBroken)}
            subMessage={t(l.signIn.TryAgainResetPassword)}
          />
        )}
        <Typography>{subtitle}</Typography>
        <ForgotPasswordForm
          onSubmit={onSubmit}
          isLoading={loading}
          setEmail={setEmail}
          email={email}
        />
      </SignInWrapper>
    </div>
  );

  return isEmailSent ? renderEmailSentContent : renderForgotPasswordForm;
};

export { ForgotPasswordContent };
