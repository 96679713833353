import { css } from "@emotion/react";

import * as dimensions from "@styles/emotion-styles/dimensions";

export const main = css`
  flex: 1 0 auto;
`;

export const container = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;

  @media (max-width: ${dimensions.phoneMax}) {
    padding: 1rem 0;
  }
`;
