import type { ReactNode } from "react";

import * as styles from "./Page.styles";

interface Props {
  children: ReactNode;
}

const Page = ({ children, ...rest }: Props) => {
  return (
    <div css={styles.page} {...rest}>
      {children}
    </div>
  );
};

export { Page };
