import type { ReactNode } from "react";

import { header } from "./SignUpHeader.styles";

interface Props {
  children: ReactNode;
}

const SignUpHeader = ({ children }: Props) => {
  return <div css={header}>{children}</div>;
};

export { SignUpHeader };
