import type { SerializedStyles } from "@emotion/react";
import type { ReactNode } from "react";

import { Container } from "@aviary/layouts";

import * as styles from "./PageContent.styles";

interface Props {
  children: ReactNode;
  customStyles?: SerializedStyles;
}

const PageContent = ({ children, customStyles, ...rest }: Props) => {
  const containerStyles = [styles.container, customStyles];

  return (
    <main css={styles.main} id="main-content" role="main" tabIndex={0}>
      <Container css={containerStyles} {...rest}>
        {children}
      </Container>
    </main>
  );
};

export { PageContent };
