import type { ReactNode } from "react";

import { AuthFooter } from "@shared/components/AuthFooter/AuthFooter";
import { AuthHeader } from "@shared/components/AuthHeader/AuthHeader";
import { AuthHeaderWrapper } from "@shared/components/AuthHeaderWrapper/AuthHeaderWrapper";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { CountrySelect } from "@unauthenticated/signupSignin/components/CountrySelect/CountrySelect";
import { Page } from "@unauthenticated/signupSignin/components/Page/Page";
import { PageContent } from "@unauthenticated/signupSignin/components/PageContent/PageContent";

interface Props {
  children: ReactNode;
}

const SignInLayout = ({ children }: Props) => {
  const { isEmerson } = useSharedGlobalConfig();
  const route = isEmerson ? authRoutes.emerson.login : authRoutes.login;

  return (
    <Page>
      <PageContent>
        <AuthHeaderWrapper>
          <AuthHeader route={route} />
          {!isEmerson && <CountrySelect />}
        </AuthHeaderWrapper>
        {children}
      </PageContent>
      <AuthFooter />
    </Page>
  );
};

export { SignInLayout };
