import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const USER_FORGOT_PASSWORD_EMAIL = gql`
  mutation UserForgotPasswordEmail_UnauthenticatedSignUpSignIn_Mutation(
    $input: UserForgotPasswordEmailInput!
  ) {
    auth {
      userForgotPasswordEmail(input: $input) {
        emailSent
        errors {
          message
        }
      }
    }
  }
`;

interface AuthUserForgotPasswordEmailInputErrors {
  message: string;
}

interface AuthUserForgotPasswordEmailMutationData {
  auth: {
    userForgotPasswordEmail: {
      emailSent: boolean;
      errors: AuthUserForgotPasswordEmailInputErrors;
    };
  };
}

interface AuthUserForgotPasswordEmailInput {
  email: string;
  storePlatform: string;
}

interface Variables {
  input: {
    attributes: AuthUserForgotPasswordEmailInput;
    captchaToken: string;
  };
}

const useUserForgotPasswordEmail = (
  options?: MutationHookOptions<AuthUserForgotPasswordEmailMutationData, Variables>
) =>
  useMutation<AuthUserForgotPasswordEmailMutationData, Variables>(
    USER_FORGOT_PASSWORD_EMAIL,
    options
  );

export type { AuthUserForgotPasswordEmailMutationData };
export { useUserForgotPasswordEmail, USER_FORGOT_PASSWORD_EMAIL };
